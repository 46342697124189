import { useState, useEffect } from 'react';

import TextInput from '../../inputs/form-inputs/TextInput';

function ComponentButton({ c, selected, handleSelectComponent, handleUnselectComponent, handleAddComponent }) {
  const isSelected = selected && selected.name === c.name;

  return (
    <label className="w-100" onMouseLeave={() => handleUnselectComponent(c.name)}>
      <input type="radio" className="card-input-element" id={c.name} name="component_option" value={c.name} onChange={handleSelectComponent} />
      <div className="card card-input">
        <div className="card-body">
          <div className="d-flex flex-column gap-3">
            {isSelected && (
              <button className={'dp-editor-button-overlay'} onClick={handleAddComponent}>
                Add
              </button>
            )}
            {c.name}
            <p className="placeholder-glow d-flex flex-column gap-1 align-items-center">
              <span className={`placeholder placeholder-sm col-7`} />
              <span className={`placeholder placeholder-sm col-3`} />
              <span className={`placeholder placeholder-sm col-12`} />
            </p>
          </div>
        </div>
      </div>
    </label>
  );
}

export default function ComponentSelectionPane({ state, dispatch, componentDefinitions, setActivePane }) {
  const [selected, setSelected] = useState(null);
  const [componentDefinitionsList, setComponentDefinitionsList] = useState(componentDefinitions);

  // Generate props using reducer
  const generateProps = (propSchema) => {
    return propSchema.reduce((acc, prop) => {
      acc[prop.name] = prop.defaultValue ?? '';
      return acc;
    }, {});
  };

  const handleAddComponent = () => {
    const props = selected.propSchema ? generateProps(selected.propSchema) : {};
    const newComponent = { ...selected, id: crypto.randomUUID(), ...(props && { props }) };

    if (state.addContentAtIndex !== undefined) {
      dispatch({ type: 'ADD_CONTENT_AT_INDEX', payload: { index: state.addContentAtIndex, content: newComponent } });
    } else {
      dispatch({ type: 'ADD_CONTENT', payload: newComponent });
    }

    setActivePane(1);
  };

  const handleSelectComponent = (name) => {
    const component = componentDefinitions.find((component) => component.name === name);
    setSelected(component);
  };

  const handleUnselectComponent = (id) => {
    const radioInput = document.getElementById(id);
    if (radioInput) {
      radioInput.checked = false;
    }
    setSelected(null); // Optionally, update the state to reflect the unselection
  };
  const handleSearchComponent = (event) => {
    const searchValue = event.target.value.toLowerCase();

    if (searchValue) {
      setComponentDefinitionsList(componentDefinitions.filter((c) => c.name.toLowerCase().includes(searchValue)));
      return;
    }

    setComponentDefinitionsList(componentDefinitions);
  };

  useEffect(() => {
    return () => {
      setSelected(null);
    };
  }, []);

  return (
    <div className="max-92-vh d-flex flex-column gap-3">
      <div className="d-flex flex-column">
        <h3>Add a new component</h3>
        <p className="text-muted mb-0">Select a component you wish to add as content.</p>
      </div>
      <div className="py-3">
        <TextInput label="Search" placeholder="Search for a component..." onChange={handleSearchComponent} />
      </div>
      <div className="overflow-auto h-100 mh-100 pe-3">
        <div className="accordion" id="standardLibraryAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#standardLibrary"
                aria-expanded="true"
                aria-controls="standardLibrary"
              >
                Standard Library (Full Width)
              </button>
            </h2>
            <div id="standardLibrary" className="accordion-collapse collapse show" data-bs-parent="#standardLibraryAccordion">
              <div className="accordion-body">
                <div className="d-flex flex-column gap-3 px-5 align-items-center justify-content-center">
                  {componentDefinitionsList && componentDefinitionsList.length ? (
                    componentDefinitionsList.map((c, index) => (
                      <ComponentButton
                        {...{
                          key: index,
                          c,
                          selected,
                          handleSelectComponent: () => handleSelectComponent(c.name),
                          handleUnselectComponent,
                          handleAddComponent,
                        }}
                      />
                    ))
                  ) : (
                    <div className="text-center text-muted my-5 py-5">No components found.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#standardLibrary2Column"
                aria-expanded="true"
                aria-controls="standardLibrary2Column"
              >
                Standard Library (2 Column)
              </button>
            </h2>
            <div id="standardLibrary2Column" className="accordion-collapse collapse show" data-bs-parent="#standardLibrary2ColumnAccordion">
              <div className="accordion-body">
                <div className="d-flex flex-column gap-3 px-5 align-items-center justify-content-center">
                  <div className="text-center text-muted my-5 py-5">No components found.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#customComponents"
                aria-expanded="true"
                aria-controls="customComponents"
              >
                Custom Components
              </button>
            </h2>
            <div id="customComponents" className="accordion-collapse collapse show" data-bs-parent="#customComponentsAccordion">
              <div className="accordion-body">
                <div className="d-flex flex-column gap-3 px-5 align-items-center justify-content-center">
                  <div className="text-center text-muted my-5 py-5">No components found.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
