import { useReducer } from 'react';

import TextInput from '../inputs/form-inputs/TextInput';
import SiteTree from './_builder_sitemap/SiteTree';

const initialState = {
  isLoading: true,
  isError: false,
  status: '',
  selectedPage: null,
  pages: [],
  currentPageChunk: 1,
  search: '',
  sort: {
    identifier: 0,
    order: 0,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SELECTED_PAGE':
      return { ...state, selectedPage: action.payload };
    case 'SET_PAGES':
      return { ...state, pages: action.payload };
    case 'SET_CURRENT_PAGE_CHUNK':
      return { ...state, currentPageChunk: action.payload };
    case 'SET_SEARCH':
      return { ...state, search: action.payload };
    case 'SET_SORT_IDENTIFIER':
      return { ...state, sort: { ...state.sort, identifier: action.payload } };
    case 'SET_SORT_ORDER':
      return { ...state, sort: { ...state.sort, order: action.payload } };
    case 'TOGGLE_IS_LOADING':
      return { ...state, isLoading: !state.isLoading };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'TOGGLE_IS_ERROR':
      return { ...state, isError: !state.isError };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    default:
      return state;
  }
}

const filterPages = (pages, keyword) => {
  return pages.filter((page) => page.title.toLowerCase().includes(keyword.toLowerCase()) || page.url_slug.toLowerCase().includes(keyword.toLowerCase()));
};

// Let's build a tree!
const buildTree = (pages, categoriesWithTemplates, withIndex) => {
  // Create a map of all pages for quick lookup
  const map = Object.fromEntries(pages.map((page) => [page.url_slug, page]));

  const urlsTree = pages.reduce((acc, page) => {
    const urlParts = page.url_slug.split('/').filter((part) => part !== '');

    let currentLevel = acc;
    let fullPath = '';

    urlParts.forEach((part) => {
      fullPath = fullPath ? `${fullPath}/${part}` : part;
      let existingPath = currentLevel.find((level) => level.url_slug === fullPath);

      if (existingPath) {
        currentLevel = existingPath.children;
      } else {
        const firstPart = urlParts[0];
        const matchingCategory = categoriesWithTemplates.find((category) => category.url_pattern === firstPart);
        const matchingExactCategory = categoriesWithTemplates.find((category) => category.url_pattern === fullPath);

        const newLevel = {
          ...map[fullPath],
          isPlaceholder: !map[fullPath],
          parent_category_id: matchingCategory?.id,
          isCategory: !!matchingExactCategory,
          isNested: matchingCategory?.is_nested,
          url_slug: fullPath,
          children: [],
        };

        currentLevel.push(newLevel);
        currentLevel = newLevel.children;
      }
    });

    return acc;
  }, []);

  if (withIndex) {
    // Always add a default home page at the top
    urlsTree.unshift({
      id: 'default_home',
      title: 'Index',
      url_slug: '/',
      ...(map['/'] ? map['/'] : {}),
      is_from_sitemap: true,
      children: [],
    });
  }

  return urlsTree;
};

export default function BuilderSitemap({ allSitePages, categoriesWithTemplates, endpoints, isSimple, className, currentPageUrlSlug }) {
  const [state, dispatch] = useReducer(reducer, { ...initialState, pages: buildTree(allSitePages, categoriesWithTemplates, true) });

  const handleSearch = (e) => {
    const filteredPages = filterPages(allSitePages, e.target.value);

    let tree;
    if (e.target.value === '') {
      tree = buildTree(filteredPages, categoriesWithTemplates, true);
    } else {
      tree = buildTree(filteredPages, categoriesWithTemplates);
    }

    dispatch({
      type: 'SET_PAGES',
      payload: tree,
    });
  };

  const currentPage = allSitePages.find((page) => page.url_slug === currentPageUrlSlug);

  return (
    <div className={`dp-site-tree ${className}`}>
      <TextInput label="Search" placeholder="Search by page title or URL..." onChange={handleSearch} />
      <SiteTree {...{ endpoints, state, dispatch, isSimple, currentPage }} />
    </div>
  );
}
