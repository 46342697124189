import StatusBadge from './StatusBadge';

export default function RowOptions({ page, endpoints, isSimple }) {
  const { currentDomain, editDynamicPageUrl, editCategoryValueUrl } = endpoints;

  const editCategoryValueLink = editCategoryValueUrl.replace(':category_id', page.parent_category_id).replace(':id', page.id);
  const editDynamicPageLink = editDynamicPageUrl.replace(':id', page.id);
  const isNavigable = page.is_from_sitemap;
  const isDynamicPage = page.is_dynamic_page;
  const editSiteManagerLink = `/_sf/admin/content/editor?path=/${page.url_slug}`;
  const editLink = page.parent_category_id ? editCategoryValueLink : isNavigable && !isDynamicPage ? editSiteManagerLink : editDynamicPageLink;
  const pageType = page.parent_category_id ? 'Category' : page.is_dynamic_page ? 'Builder' : page.is_from_sitemap ? 'Sitemap' : 'Placeholder';

  if (isSimple) {
    return (
      <div className="dropdown">
        <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fe fe-more-horizontal" />
        </button>
        <ul className="dropdown-menu">
          {editLink && (
            <li>
              <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={editLink}>
                Edit
              </a>
            </li>
          )}
          {isNavigable && (
            <li>
              <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={`${currentDomain}${page.url_slug}`}>
                View
              </a>
            </li>
          )}
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li className="d-flex align-items-center justify-content-center pb-2">
            <StatusBadge {...{ page }} />
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="dp-row-option-full">
      <span className="text-muted">{pageType}</span>
      <a className={`btn ${!editLink && 'disabled border-0'}`} target="_blank" rel="noopener noreferrer" href={editLink}>
        <i className={`fe fe-edit ${!editLink && 'invisible'}`} />
      </a>
      <a className={`btn ${!isNavigable && 'disabled border-0'}`} target="_blank" rel="noopener noreferrer" href={`${currentDomain}${page.url_slug}`}>
        <i className={`fe fe-external-link ${!isNavigable && 'invisible'}`} />
      </a>
    </div>
  );
}
