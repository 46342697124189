export const builderReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.payload };
    case 'UPDATE_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'ADD_METADATA':
      return { ...state, data: { ...state.data, metadata: [...state.data.metadata, action.payload] } };
    case 'ADD_METADATA_AT_INDEX':
      const newMetadata = [...state.data.metadata];
      newMetadata.splice(action.payload.index, 0, action.payload.metadata);
      return {
        ...state,
        data: {
          ...state.data,
          metadata: newMetadata,
        },
      };
    case 'UPDATE_METADATA':
      return { ...state, data: { ...state.data, metadata: state.data.metadata.map((item) => (item.id === action.payload.id ? action.payload : item)) } };
    case 'REMOVE_METADATA':
      return { ...state, data: { ...state.data, metadata: state.data.metadata.filter((item) => item.id !== action.payload.id) } };
    case 'UPDATE_SEO':
      return { ...state, data: { ...state.data, seo: { ...state.data.seo, ...action.payload } } };
    case 'UPDATE_INDIVIDUAL_CONTENT':
      return { ...state, data: { ...state.data, content: state.data.content.map((item) => (item.id === action.payload.id ? action.payload : item)) } };
    case 'ADD_CONTENT_AT_INDEX': {
      const newContent = [...state.data.content];
      newContent.splice(action.payload.index, 0, action.payload.content);
      return {
        ...state,
        data: {
          ...state.data,
          content: newContent,
        },
      };
    }
    case 'ADD_CONTENT':
      return { ...state, data: { ...state.data, content: [...state.data.content, action.payload] } };
    case 'REMOVE_CONTENT':
      return { ...state, data: { ...state.data, content: state.data.content.filter((item) => item.id !== action.payload.id) } };
    case 'SET_ADD_CONTENT_AT_INDEX':
      return { ...state, addContentAtIndex: action.payload.addContentAtIndex };
    case 'SET_COMPONENT_BEING_EDITED_INDEX':
      return { ...state, componentBeingEditedIndex: action.payload.index };
    case 'SET_METADATA_BEING_EDITED_INDEX':
      return { ...state, metadataBeingEditedIndex: action.payload.index };
    default:
      return state;
  }
};

const formatMetadata = (metadata) => {
  return Object.entries(metadata).map(([key, value]) => ({ id: crypto.randomUUID(), key, value }));
};

export const convertMetadata = (metadata) => {
  return metadata.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {});
};

export const formatContent = (content) => {
  return content.map((item) => {
    const { propSchema, ...rest } = item;
    return rest;
  });
};

export const getInitialBuilderState = (dynamicPage) => {
  const blankSeo = {
    title: '',
    description: '',
    keywords: '',
    canonical: '',
    robots: '',
    alternate: { url: '', hreflang: '' },
    json_schema: '',
  };

  return {
    isLoading: false,
    isError: false,
    status: '',
    error: null,
    errorKeys: [],
    addContentAtIndex: undefined,
    componentBeingEditedIndex: undefined,
    metadataBeingEditedIndex: undefined,
    data: dynamicPage
      ? {
          ...dynamicPage,
          metadata: formatMetadata(dynamicPage.metadata),
          seo: dynamicPage.seo ?? blankSeo,
        }
      : {
          title: '',
          metadata: [],
          seo: blankSeo,
          content: [],
          url_slug: '',
        },
  };
};

export const VIEWPORT_OPTIONS = [
  { name: 'Full', className: 'full-size', icon: 'fe fe-maximize' },
  { name: 'Desktop', className: 'desktop', icon: 'fe fe-monitor' },
  { name: 'Tablet', className: 'tablet', icon: 'fe fe-tablet' },
  { name: 'Mobile', className: 'mobile', icon: 'fe fe-smartphone' },
];

export const ERRORS = [
  { key: 'title_empty', message: 'Title is required!' },
  { key: 'url_slug_empty', message: 'URL Slug is required!' },
];
