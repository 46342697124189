import { useReducer, useState } from 'react';
import { getInitialBuilderState, builderReducer, convertMetadata, formatContent } from '../lib';
import { getQueryParam } from '../../utils/UrlFormatters';

import BuilderEditor from '../BuilderEditor';
import BuilderSidebar from '../BuilderSidebar';

export default function NewDynamicPage({ allSitePages, categoriesWithTemplates, componentDefinitions, endpoints }) {
  const { indexUrl, createUrl, previewSource } = endpoints;

  const [initialState, _] = useState(getInitialBuilderState());

  const [state, dispatch] = useReducer(builderReducer, initialState);

  const handleSave = () => {
    const parentSlug = getQueryParam('parent_slug');

    const payload = {
      ...state.data,
      url_slug: parentSlug ? `${parentSlug}/${state.data.url_slug}` : state.data.url_slug,
      metadata: convertMetadata(state.data.metadata),
      content: formatContent(state.data.content),
    };

    fetch(`${createUrl}.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to save page');
        }
        window.location.replace(indexUrl);
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE', payload: { isError: true, error: JSON.stringify(error) } });
      });
  };

  return (
    <div className="row dp-editor">
      <div className="col-4">
        <BuilderSidebar {...{ state, dispatch, allSitePages, categoriesWithTemplates, componentDefinitions, endpoints }} />
      </div>
      <div className="col-8">
        <BuilderEditor {...{ state, dispatch, handleSave, previewSource, indexUrl, initialState }} />
      </div>
    </div>
  );
}
