import React from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export default function SortableItem(props) {
  const { id, children } = props;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div className="d-flex align-items-center w-100 gap-3">
        <div {...attributes} {...listeners}>
          ⣿
        </div>
        <div className="w-100">{children}</div>
      </div>
    </div>
  );
}
