import { useReducer, useState } from 'react';
import { getInitialBuilderState, builderReducer, convertMetadata, formatContent } from '../lib';

import BuilderEditor from '../BuilderEditor';
import BuilderSidebar from '../BuilderSidebar';

export default function EditDynamicPage({ dynamicPage, allSitePages, categoriesWithTemplates, componentDefinitions, endpoints }) {
  const { indexUrl, pageUrl, previewSource } = endpoints;

  const [initialState, _] = useState(getInitialBuilderState(dynamicPage));

  const [state, dispatch] = useReducer(builderReducer, initialState);

  const handleSave = () => {
    const { site_id, created_at, updated_at, ...data } = state.data;

    const payload = {
      ...data,
      metadata: convertMetadata(data.metadata),
      content: formatContent(data.content),
    };

    fetch(`${pageUrl}.json`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to save page');
        }
        window.location.replace(indexUrl);
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE', payload: { isError: true, error: JSON.stringify(error) } });
      });
  };

  const handleDelete = () => {
    fetch(`${pageUrl}.json`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to delete page');
        }
        window.location.replace(indexUrl);
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE', payload: { isError: true, error: JSON.stringify(error) } });
      });
  };

  return (
    <div className="row dp-editor">
      <div className="col-4">
        <BuilderSidebar
          {...{ state, dispatch, allSitePages, categoriesWithTemplates, componentDefinitions, endpoints, currentPageUrlSlug: dynamicPage.url_slug }}
        />
      </div>
      <div className="col-8">
        <BuilderEditor {...{ state, dispatch, handleSave, handleDelete, previewSource, indexUrl, initialState }} />
      </div>
    </div>
  );
}
