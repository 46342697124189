import { useEffect, useState, useRef } from 'react';
import { ERRORS, VIEWPORT_OPTIONS } from './lib';

import ViewportOptions from './_builder_editor/ViewportOptions';
import PageOptionsBar from './_builder_editor/PageOptionsBar';

export default function BuilderEditor({ state, dispatch, previewSource, indexUrl, handleSave, handleDelete, initialState }) {
  const iFrameRef = useRef(null);
  const [viewport, setViewport] = useState(0);

  const handleLoad = () => {
    const content = JSON.stringify(state.data.content);
    iFrameRef.current.contentWindow.postMessage({ type: 'SET_CONTENT', message: content }, '*');
  };

  const validatePage = () => {
    const errorKeys = [];

    if (!state.data.title) {
      errorKeys.push(ERRORS[0].key);
    }

    if (!state.data.url_slug) {
      errorKeys.push(ERRORS[1].key);
    }

    const isError = errorKeys.length > 0;
    const errorMessages = errorKeys.map((err) => ERRORS.find((error) => error.key === err).message).join(', ');

    dispatch({
      type: 'UPDATE',
      payload: {
        isError,
        error: isError ? errorMessages : null,
        errorKeys,
      },
    });
  };

  useEffect(() => {
    validatePage();
  }, [state.data.title, state.data.url_slug]);

  useEffect(() => {
    if (state.data.content?.length === 0) {
      return;
    }

    const iframe = iFrameRef.current;

    if (iframe) {
      iframe.addEventListener('load', handleLoad);
      handleLoad();
    }

    return () => {
      if (iframe) iframe.removeEventListener('load', handleLoad);
    };
  }, [state.data.content]);

  const cacheBustedSrc = `${previewSource}?t=${new Date().getTime()}`;

  return (
    <div className="dp-editor">
      <div className="top-input">
        <div className="placeholder-left" />
        <ViewportOptions {...{ viewport, setViewport }} />
        <PageOptionsBar {...{ state, dispatch, initialState, indexUrl, handleSave, handleDelete }} />
      </div>
      <div className="iframe-preview">
        <iframe ref={iFrameRef} src={cacheBustedSrc} className={`${VIEWPORT_OPTIONS[viewport].className}`} />
      </div>
    </div>
  );
}
