import { useState } from 'react';

import BuilderSitemap from './BuilderSitemap';
import ComponentsList from './_builder_sidebar/ComponentsList';
import PageSettings from './_builder_sidebar/PageSettings';
import PageMetadataSEO from './_builder_sidebar/PageMetadataSEO';
import ComponentSelectionPane from './_builder_sidebar/ComponentSelectionPane';
import ComponentSettingsPane from './_builder_sidebar/ComponentSettingsPane';
import MetadataSettingsPane from './_builder_sidebar/MetadataSettingsPane';

function BuilderTabs({ BUILDER_TABS, activeTab }) {
  return <div className="mh-100 h-100 overflow-auto pe-3">{BUILDER_TABS[activeTab].component}</div>;
}

function BuilderTabsNavigation({ BUILDER_TABS, activeTab, setActiveTab }) {
  return (
    <div className="dp-editor-sidebar-bottom">
      {BUILDER_TABS.map((tab, index) => (
        <button key={index} className="navigation-button" onClick={() => setActiveTab(index)}>
          <div className={`button-content ${activeTab === index && 'active-tab'}`}>
            <div className="tab-number">{index + 1}</div>
            <span>{tab.label}</span>
          </div>
        </button>
      ))}
    </div>
  );
}

function TopNavigation({ buttons, setActivePane }) {
  return buttons.map((button, index) => (
    <button key={index} className="navigation-button" onClick={() => setActivePane(button.toPane)}>
      {button.icon && <i className={`fe ${button.icon}`} />}
      {button.label}
      {button.iconEnd && <i className={`fe ${button.iconEnd}`} />}
    </button>
  ));
}

export default function BuilderSidebar({ state, dispatch, allSitePages, categoriesWithTemplates, componentDefinitions, endpoints, currentPageUrlSlug }) {
  const [activePane, setActivePane] = useState(1);
  const [activeTab, setActiveTab] = useState(0);

  const BUILDER_TABS = [
    {
      label: 'Settings',
      component: <PageSettings {...{ state, dispatch, handleChangeActiveTab: setActiveTab }} />,
    },
    {
      label: 'SEO',
      component: <PageMetadataSEO {...{ state, dispatch, setActivePane }} />,
    },
    {
      label: 'Content',
      component: <ComponentsList {...{ state, dispatch, componentDefinitions, activePane, setActivePane }} />,
    },
  ];

  const SIDEBAR_PANE = [
    {
      label: 'Sitemap',
      topBarClassName: 'justify-content-end',
      buttons: [{ label: 'Builder', iconEnd: 'fe-arrow-right', toPane: 1 }],
      component: <BuilderSitemap {...{ allSitePages, categoriesWithTemplates, endpoints, isSimple: true, currentPageUrlSlug, className: 'max-92-vh' }} />,
    },
    {
      label: 'Page Builder',
      buttons: [{ label: 'Sitemap', icon: 'fe-arrow-left', toPane: 0 }],
      component: (
        <>
          <BuilderTabs {...{ BUILDER_TABS, activeTab }} />
          <BuilderTabsNavigation {...{ BUILDER_TABS, activeTab, setActiveTab }} />
        </>
      ),
    },
    {
      label: 'Component Selection',
      buttons: [{ label: 'Back', icon: 'fe-arrow-left', toPane: 1 }],
      component: <ComponentSelectionPane {...{ state, dispatch, componentDefinitions, setActivePane }} />,
    },
    {
      label: 'Edit Component',
      buttons: [{ label: 'Back', icon: 'fe-arrow-left', toPane: 1 }],
      component: <ComponentSettingsPane {...{ state, dispatch, componentDefinitions, setActivePane }} />,
    },
    {
      label: 'Edit Metadata',
      buttons: [{ label: 'Back', icon: 'fe-arrow-left', toPane: 1 }],
      component: <MetadataSettingsPane {...{ state, dispatch, setActivePane }} />,
    },
  ];

  return (
    <div className="dp-editor-sidebar">
      <div className={`dp-editor-sidebar-top ${SIDEBAR_PANE[activePane].topBarClassName}`}>
        <TopNavigation {...{ buttons: SIDEBAR_PANE[activePane].buttons, setActivePane }} />
      </div>
      <div className="dp-editor-sidebar-main-container">{SIDEBAR_PANE[activePane].component}</div>
    </div>
  );
}
