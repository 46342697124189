import TextInput from '../../inputs/form-inputs/TextInput';
import SwitchInput from '../../inputs/form-inputs/SwitchInput';

const snakeToRegularString = (str) => {
  return str
    .split('_') // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words with spaces
};

function PropSetting({ name, type, defaultValue, options, isRequired, component, dispatch }) {
  const value = component.props[name];

  const handleStringChange = (e) => {
    dispatch({ type: 'UPDATE_INDIVIDUAL_CONTENT', payload: { ...component, props: { ...component.props, [name]: e.target.value } } });
  };

  const handleBooleanChange = (e) => {
    dispatch({ type: 'UPDATE_INDIVIDUAL_CONTENT', payload: { ...component, props: { ...component.props, [name]: e.target.checked } } });
  };

  const handleArrayChange = (e) => {
    dispatch({ type: 'UPDATE_INDIVIDUAL_CONTENT', payload: { ...component, props: { ...component.props, [name]: JSON.parse(e.target.value) } } });
  };

  const renderStringInput = () => {
    if (options && options.length) {
      return (
        <select className="form-select" value={value || ''} onChange={handleStringChange}>
          <option value="" disabled>
            Select an option
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    }

    return <TextInput value={value || ''} onChange={handleStringChange} />;
  };

  const renderBooleanInput = () => <SwitchInput checked={!!value} onChange={handleBooleanChange} />;

  const renderArrayInput = () => (
    <select className="form-select" value={JSON.stringify(value) || ''} onChange={handleArrayChange}>
      <option value="" disabled>
        Select an option
      </option>
      {options.map((option, index) => (
        <option key={index} value={JSON.stringify(option.value)}>
          {option.label}
        </option>
      ))}
    </select>
  );

  const renderOptions = {
    string: renderStringInput,
    boolean: renderBooleanInput,
    array: renderArrayInput,
  };

  if (type !== 'string' && type !== 'boolean' && type !== 'array') {
    return <div>This prop is not configured properly.</div>;
  }

  return (
    <div className="d-flex flex-column gap-2">
      <label className="text-muted">{snakeToRegularString(name)}</label>
      {renderOptions[type]()}
    </div>
  );
}

export default function ComponentSettingsPane({ state, dispatch, componentDefinitions, setActivePane }) {
  const component = state.data.content[state.componentBeingEditedIndex];
  const definition = componentDefinitions.find((c) => c.name === component?.name);

  const handleRemoveComponent = () => {
    dispatch({ type: 'REMOVE_CONTENT', payload: { id: component.id } });
    setActivePane(1);
  };

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <h3>Editing {definition?.name}</h3>
          <p className="text-muted mb-0">This component is #{state.componentBeingEditedIndex + 1} on the components list.</p>
        </div>
        <div>
          <button type="button" className="btn btn-danger" onClick={handleRemoveComponent}>
            Remove
          </button>
        </div>
      </div>
      {definition?.propSchema?.map((prop, index) => (
        <PropSetting key={index} {...{ component, dispatch, ...prop }} />
      ))}
    </div>
  );
}
