import Card from '../../../card/Card';
import SortableItem from '../../../dnd-kit/SortableItem/SortableItem';

export default function SortableComponent({ index, component, componentBeingEditedIndex, setActivePane, dispatch }) {
  const handleEditComponent = () => {
    dispatch({ type: 'SET_COMPONENT_BEING_EDITED_INDEX', payload: { index: componentBeingEditedIndex } });
    setActivePane(3);
  };

  return (
    <SortableItem key={component.id} id={component.id}>
      <Card bodyClass="p-4">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div className="d-flex gap-2 align-items-center">
            <span className="number-bullet-point">{index + 1}</span>
            <h3 className="mb-0">{component.name}</h3>
          </div>
          <button className="btn btn-sm" onClick={handleEditComponent}>
            <i className="fe fe-edit" />
          </button>
        </div>
      </Card>
    </SortableItem>
  );
}
