import { useEffect, useCallback } from 'react';
import { Tooltip } from 'bootstrap';

import ConfirmationDialog from '../../confirmation-dialog/ConfirmationDialog';

// TODO: Replace this with a library like lodash or ramda
function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  if (obj1.constructor !== obj2.constructor) {
    return false;
  }

  if (obj1 instanceof Date) {
    return obj1.getTime() === obj2.getTime();
  }

  if (obj1 instanceof RegExp) {
    return obj1.toString() === obj2.toString();
  }

  if (obj1 instanceof Map) {
    if (obj1.size !== obj2.size) {
      return false;
    }
    for (let [key, value] of obj1) {
      if (!obj2.has(key) || !deepEqual(value, obj2.get(key))) {
        return false;
      }
    }
    return true;
  }

  if (obj1 instanceof Set) {
    if (obj1.size !== obj2.size) {
      return false;
    }
    for (let value of obj1) {
      if (!obj2.has(value)) {
        return false;
      }
    }
    return true;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export default function PageOptionsBar({ state, initialState, handleSave, indexUrl, handleDelete }) {
  const hasUnsavedChanges = !deepEqual(state.data, initialState.data);

  // Uncomment this to see the differences between the state and the initial state
  // Detailed logging to identify differences
  // if (!deepEqual(state.data, initialState.data)) {
  //   console.log('Differences found:');
  //   for (const key of Object.keys(state.data)) {
  //     if (!deepEqual(state.data[key], initialState.data[key])) {
  //       console.log(`Difference in property "${key}":`);
  //       console.log('State.data:', state.data[key]);
  //       console.log('initialState.data:', initialState.data[key]);
  //     }
  //   }
  // }

  const handleBeforeUnload = useCallback((e) => {
    e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
  }, []);

  const handleOnClickDelete = () => {
    // Remove the beforeunload event listener
    window.removeEventListener('beforeunload', handleBeforeUnload);
    // Call the delete function
    handleDelete();
  };

  const handleOnClickSave = () => {
    // Remove the beforeunload event listener
    window.removeEventListener('beforeunload', handleBeforeUnload);
    // Call the save function
    handleSave();
  };

  const handleDiscardChanges = () => {
    // Remove the beforeunload event listener
    window.removeEventListener('beforeunload', handleBeforeUnload);
    // Navigate to the index page
    window.location.href = indexUrl;
  };

  useEffect(() => {
    if (!hasUnsavedChanges) return;

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    tooltips.forEach((tooltip) => {
      new Tooltip(tooltip);
    });

    return () => {
      tooltips.forEach((tooltip) => {
        const tooltipInstance = Tooltip.getInstance(tooltip);
        if (tooltipInstance) {
          tooltipInstance.dispose();
        }
      });
    };
  }, [state]);

  return (
    <div className="d-flex gap-3">
      <div className="dropdown">
        <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fe fe-more-horizontal" />
        </button>
        <ul className="dropdown-menu">
          <li>
            {hasUnsavedChanges ? (
              <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#discardConfirmationModal-builder">
                Discard Changes
              </button>
            ) : (
              <a className="dropdown-item" href={indexUrl}>
                Discard Changes
              </a>
            )}
          </li>
          {handleDelete && (
            <>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteConfirmationModal-builder">
                  Delete Page
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className="d-flex gap-3 align-items-center justify-content-center">
        {state.isError && <i className="fe fe-info text-danger" data-bs-toggle="tooltip" data-bs-title={state.error} />}
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#saveConfirmationModal-builder"
          disabled={state.isError || !hasUnsavedChanges}
        >
          Save Changes
        </button>
      </div>
      {handleDelete && (
        <ConfirmationDialog
          id="deleteConfirmationModal-builder"
          title="Confirm Delete"
          message="Are you sure you want to delete this page?"
          confirmButtonText="Delete"
          confirmButtonClass="btn-danger"
          onConfirm={handleOnClickDelete}
        />
      )}
      <ConfirmationDialog
        id="discardConfirmationModal-builder"
        title="Confirm leave"
        message="You have unsaved changes, are you sure you want to leave?"
        confirmButtonText="Leave"
        confirmButtonClass="btn-secondary"
        onConfirm={handleDiscardChanges}
      />
      <ConfirmationDialog
        id="saveConfirmationModal-builder"
        title="Confirm Save"
        message="Are you sure you want to save this new page?"
        confirmButtonText="Save"
        confirmButtonClass="btn-primary"
        onConfirm={handleOnClickSave}
      />
    </div>
  );
}
