import RowOptions from './RowOptions';
import StatusBadge from './StatusBadge';

export default function TreeLeaf({ page, endpoints, isSimple, currentPage, handleAddPage }) {
  const isCurrent = currentPage?.url_slug === page.url_slug;

  return (
    <div key={page.id} className="d-flex flex-column">
      <div className={`d-flex align-items-center justify-content-between ${isCurrent && 'dp-current-page'}`}>
        <div className="d-flex gap-3 align-items-center">
          <div className="d-flex flex-column justify-content-center">
            <span className="dp-page-title" data-bs-toggle="tooltip" data-bs-title={page.title}>
              {isCurrent && <span className="badge bg-primary">Editing:</span>}
              <strong>{page.title}</strong>
            </span>
            <span className="dp-page-url-slug text-muted" data-bs-toggle="tooltip" data-bs-title={page.url_slug}>
              {page.url_slug}
            </span>
          </div>
        </div>
        {isSimple ? (
          <RowOptions {...{ page, endpoints, isSimple }} />
        ) : (
          <div className="d-flex gap-4 align-items-center">
            <StatusBadge {...{ page }} />
            <RowOptions {...{ page, endpoints }} />
          </div>
        )}
      </div>
      {page.isNested || page.isCategory ? (
        <button className="dp-add-in-between" onClick={() => handleAddPage(page)}>
          <hr />
          <i className="fe fe-plus" />
          <hr />
        </button>
      ) : (
        <div className="dp-add-in-between-placeholder" />
      )}
    </div>
  );
}
